
import React from 'react';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const url = "https://vm5045.kaj.pouta.csc.fi/api";

const OtherSensorComponent = ({ dataBySensor, lastIndicesBySensor, sensorId, selectedValue }) => {
  const sensorData = dataBySensor[sensorId] || [];
  const lastIndex = lastIndicesBySensor[sensorId];
  const hasData = sensorData.length > 0 && lastIndex >= 0;

  return (
    <div
      key={sensorId}
      className={`sensor-container ${sensorId}`}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '98%',
        marginTop: '2%'
      }}
    >
      {hasData && <h2>{sensorData[lastIndex].type}</h2>}
      <ResponsiveContainer width="100%" height={600}>
        <LineChart data={sensorData} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis
            dataKey={selectedValue}
            domain={
                selectedValue === 'temperature_c'
                  ? [
                      dataMin => (Math.max(Number.isNaN(parseInt(dataMin)) ? 0 : parseInt(dataMin) - 5, 0)),
                      dataMax => (Math.max(Number.isNaN(parseInt(dataMax)) ? 0 : parseInt(dataMax) + 5))
                    ]
                  : selectedValue === 'pH'
                  ? [
                      dataMin => (Math.max(Number.isNaN(parseInt(dataMin)) ? 0 : parseInt(dataMin) - 2, 0)),
                      dataMax => (Math.max(Number.isNaN(parseInt(dataMax)) ? 0 : parseInt(dataMax) + 2))
                    ]
                  : selectedValue === 'luminosity'
                  ? [
                      0,
                      dataMax => (Math.max(Number.isNaN(parseInt(dataMax)) ? 0 : Math.max(parseInt(dataMax) + 1000)))
                    ]
                  : ['auto', 'auto']
              } allowDataOverflow={true}

           
          />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey={selectedValue}
            stroke="#8884d8"
            activeDot={{ r: 8 }}
            dot={{ r: 2 }}
          />
        </LineChart>
      </ResponsiveContainer>
      <div>
        {hasData ? (
          <>
            <h2>{`Current values for Sensor Id ${sensorId}:`}</h2>
            <h3>{`Time: ${sensorData[lastIndex].date}`}</h3>
            <h3 style={{ color: '#8884d8' }}>{`Liquid Temperature: ${sensorData[lastIndex].temperature_c} °C, Luminosity: ${sensorData[lastIndex].luminosity} Lux, pH: ${sensorData[lastIndex].pH}`}</h3>

            <img
              src={`${url}/images/${sensorData[lastIndex].img_name}`}
              alt="sensor data"
              style={{ maxWidth: '100%', height: 'auto' }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `${url}/images/logo.png`;
              }}
            />
          </>
        ) : (
          <h3>No data available</h3>
        )}
      </div>
    </div>
  );
};

export default OtherSensorComponent;