

import React from 'react';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const CO2SensorComponent = ({ dataBySensor, lastIndicesBySensor, sensorId, selectedValue }) => {
  const sensorData = dataBySensor[sensorId] || [];
  const lastIndex = lastIndicesBySensor[sensorId];
  const hasData = sensorData.length > 0 && lastIndex >= 0;

  return (
    <div
      key={sensorId}
      className={`sensor-container ${sensorId}`}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '98%',
        marginTop: '2%'
      }}
    >
      <h2>CO2 Data</h2>
      <ResponsiveContainer width="100%" height={600}>
        <LineChart data={sensorData} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis
            dataKey={selectedValue}
            type="number"
            // domain={[dataMin => (parseInt(dataMin) / 2), dataMax => (parseInt(dataMax) * 2)]}
            // domain={[dataMin => (Math.max(parseInt(dataMin), 0) / 2), dataMax => (parseInt(dataMax) * 2)]}
            domain={
                selectedValue === 'co2'
                  ? [
                      dataMin => (Math.max(Number.isNaN(parseInt(dataMin)) ? 0 : parseInt(dataMin) - 5000, 0)),
                      dataMax => (Math.max(Number.isNaN(parseInt(dataMax)) ? 0 : parseInt(dataMax) + 5000))
                    ]
                  : selectedValue === 'temperature_room'
                  ? [
                      dataMin => (Math.max(Number.isNaN(parseInt(dataMin)) ? 0 : parseInt(dataMin) -  10, 0)),
                      dataMax => (Math.max(Number.isNaN(parseInt(dataMax)) ? 0 : parseInt(dataMax) + 10))
                    ]
                  : selectedValue === 'Humidity'
                  ? [
                      dataMin => (Math.max(Number.isNaN(parseInt(dataMin)) ? 0 : parseInt(dataMin) / 2, 0)),
                      100
                    ]
                  : ['auto', 'auto']
              }
              allowDataOverflow={true}




          />
          <Tooltip />
          <Legend />
          {hasData && (
            <>
              <Line
                type="monotone"
                dataKey={`${selectedValue}1`}
                stroke="#3ea832"
                name={`CO2 Sensor ${sensorData[lastIndex].sensor_name1}`}
                activeDot={{ r: 8 }}
                dot={{ r: 2 }}
              />
              <Line
                type="monotone"
                dataKey={`${selectedValue}2`}
                stroke="#d42215"
                name={`CO2 Sensor ${sensorData[lastIndex].sensor_name2}`}
                activeDot={{ r: 8 }}
                dot={{ r: 2 }}
              />
              <Line
                type="monotone"
                dataKey={selectedValue}
                stroke="#8884d8"
                name={`CO2 Sensor ${sensorData[lastIndex].sensor_name}`}
                activeDot={{ r: 8 }}
                dot={{ r: 2 }}
              />
            </>
          )}
        </LineChart>
      </ResponsiveContainer>
      <div>
        {hasData ? (
          <>
            <h2>{`Current values from CO2 sensors:`}</h2>
            <h3>{`Time: ${sensorData[lastIndex].date}`}</h3>
            <h3 style={{ color: '#8884d8' }}>{`CO2 sensor: ${sensorData[lastIndex].sensor_name}, CO2 value: ${sensorData[lastIndex].co2} ppm 
              Humidity: ${sensorData[lastIndex].Humidity}%, Room Temperature: ${sensorData[lastIndex].temperature_room} °C`}
            </h3>
            <h3 style={{ color: '#d42215' }}>{`CO2 sensor: ${sensorData[lastIndex].sensor_name2}, CO2 value: ${sensorData[lastIndex].co22} ppm 
              Humidity: ${sensorData[lastIndex].Humidity2}%, Room Temperature: ${sensorData[lastIndex].temperature_room2} °C`}
            </h3>
            {selectedValue === 'co2' && (
              <h3 style={{ color: '#3ea832' }}>{`CO2 sensor: ${sensorData[lastIndex].sensor_name1}, CO2 value: ${sensorData[lastIndex].co21} ppm`}
              </h3>
            )}
          </>
        ) : (
          <h3>No data available</h3>
        )}
      </div>
    </div>
  );
};

export default CO2SensorComponent;