import React, { useEffect, useState } from 'react';
import './App.css';
import { Select } from 'antd';
import 'antd/dist/reset.css';
import axios from 'axios';
import logo from './images/logo.png';
import CsvDownloadButton from 'react-json-to-csv'
import { DateTime } from "luxon";
import Switch from '@mui/material/Switch';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CO2SensorComponent from './CO2SensorComponent';
import OtherSensorComponent from './OtherSensorComponent';






function App() {
  
  const { Option } = Select;

  const [dataBySensor, setDataBySensor] = useState({});
  const [lastIndicesBySensor, setlastIndicesBySensor] = useState([]);
  const [fullData, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(localStorage.getItem('selectedValue') || 'co2');
  const [selectedTimeRange, setSelectedTimeRange] = useState(localStorage.getItem('selectedTimeRange') || null);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [selectedTab, setSelectedTab] = useState('co2Tab');
  
  


  const url="https://vm5045.kaj.pouta.csc.fi/api"
  // const url="http://localhost:3080/api/events"


  useEffect(() => {
    // Function to determine the default time range based on the device type
    const getDefaultTimeRange = () => {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      return isMobile ? '1' : '7'; // '1' for mobile, '7' for non-mobile
    };
  
    // Retrieve values from local storage if they exist
    const savedSelectedTimeRange = localStorage.getItem('selectedTimeRange');
    const savedSelectedValue = localStorage.getItem('selectedValue');
    const savedSelectedTab = localStorage.getItem('selectedTab');
  
    // Determine the default value based on the selected tab
    const defaultSelectedValue = savedSelectedTab === 'co2Tab' ? 'co2' : 'temperature_c';
  
    // If values are not present in local storage, use defaults
    setSelectedTimeRange(savedSelectedTimeRange || getDefaultTimeRange());
    setSelectedValue(savedSelectedValue || defaultSelectedValue);
    setSelectedTab(savedSelectedTab || 'co2Tab');
  }, []);
  
  function handleChange(value) {
    // Save the selected value to local storage
    localStorage.setItem('selectedValue', value);
    setSelectedValue(value);
  }
  
  const handleChangeTab = (event, newValue) => {
    // Save the selected tab to local storage
    localStorage.setItem('selectedTab', newValue);
    setSelectedTab(newValue);
  
    // Reset the selected value when the tab changes
    setSelectedValue(newValue === 'co2Tab' ? 'co2' : 'temperature_c');
  };



  useEffect(() => {
    localStorage.setItem('selectedTimeRange', selectedTimeRange);
    loadData(selectedValue);
  }, [selectedValue, selectedTimeRange, selectedTab]);


  const toggleAutoRefresh = () => {
    setAutoRefresh(!autoRefresh);
  };

  // Effect to trigger data reload every 6 seconds if auto-refresh is enabled
  useEffect(() => {
    let intervalId;

    if (autoRefresh) {
      intervalId = setInterval(() => {
        loadData(selectedValue);
      }, 6000);
    }

    return () => clearInterval(intervalId);
  }, [autoRefresh, selectedTimeRange, selectedValue, selectedTab]);






  function formatNumber(value, decimalPlaces) {
    if (typeof value === 'number') {
        // Check if the value is non-negative
        if (value >= 0) {
            return value.toFixed(decimalPlaces);
        } 
        // If value is negative, return undefined 
        return;
    }
    // Return the original value if it's not a number
    return value;
}

  const loadData = async () => {
    try {
      let newStartDate, newEndDate;
      // let newStartDate, newEndDate, newSelectedId;
      const now = DateTime.now();
      const allTimeStartDate = DateTime.fromISO("2023-09-01");
      const comparisonDateTime = DateTime.fromISO("2023-11-28T14:32:32.000Z");
  
      if (selectedTimeRange === "allTime") {
        newStartDate = allTimeStartDate;
        newEndDate = now;
        // newStartDate = DateTime.fromISO("2024-05-30T00:32:32.000Z");
        // newEndDate = DateTime.fromISO("2024-05-30T01:32:32.000Z");
      } else {
        newStartDate = now.minus({ hours: parseInt(selectedTimeRange) });
        newEndDate = now;
      }

      // if (selectedTab === "co2Tab") {
      //   newSelectedId = 1;
      // } else {
      //   newSelectedId = parseInt(selectedTab);
      // }
  
      const response = await axios.get(url, {
        params: {
          startDate: newStartDate.toISO(),
          endDate: newEndDate.toISO(),
          // sensorId: newSelectedId
        },
      });
  
      const obj = response.data;
      const dataBySensorId = {};
      setData(obj);
      let counter = 0;

  
      Object.keys(obj).forEach(key => {
        const sensorId = obj[key]["sensor_id"];
        if (!dataBySensorId[sensorId]) {
          dataBySensorId[sensorId] = [];
        }
  
        const dateTime = DateTime.fromISO(obj[key]["datetime"]);
        const newData = {
          "date": dateTime.setLocale('fi').toFormat('f'),
          "temperature_c": formatNumber(obj[key]["temperature_c"], 1),
          "luminosity": obj[key]["full_spectrum_lux"],
          "pH": formatNumber(obj[key]["ph"], 1),
          "img_name": obj[key]["img_name"],
          "type": obj[key]["type"],
          "sensor_name": obj[key]["sensor_name"],
          "co2": formatNumber(obj[key]["co2"], 0),
          "sensor_name1": obj[key]["sensor_name1"],
          "co21": formatNumber(obj[key]["co2_1"], 0),
          "Humidity": formatNumber(obj[key]["humidity_room"], 1),
          "temperature_room": formatNumber(obj[key]["tempertature_room"], 1),
          "sensor_name2": obj[key]["sensor_name2"],
          "co22": formatNumber(obj[key]["co2_2"], 0),
          "Humidity2": formatNumber(obj[key]["humidity_room2"], 1),
          "temperature_room2": formatNumber(obj[key]["temperature_room2"], 1),
          "o2": formatNumber(obj[key]["o2"], 0),
          "h2": formatNumber(obj[key]["h2"], 0),
        };



  
        let addData = true;
        const timeRange = parseInt(selectedTimeRange);
        const isRecent = dateTime > comparisonDateTime;
  
        if (timeRange >= 24 && timeRange <= 168 && isRecent) {
          addData = counter % 10 === 0;
        } else if (timeRange > 168) {
          addData = isRecent ? counter % 50 === 0 : counter % 5 === 0;
        }

        // Conditional Data Insertion
        // if (
        //   (["co2", "Humidity", "temperature_room"].includes(selectedValue) && sensorId === 1 && addData) ||
        //   (!["co2", "Humidity", "temperature_room"].includes(selectedValue) && addData)
        // ) {
        //   dataBySensorId[sensorId].push(newData);
        // }

        if (addData) {
          dataBySensorId[sensorId].push(newData);
        }
          


        // dataBySensorId[sensorId].push(newData);
        counter += 1;
      });

  
      // Set the state variable with the data split by sensor_id
      setDataBySensor(dataBySensorId);


  
      // Get the last index for each sensor and store it in an object
      Object.keys(dataBySensorId).forEach(sensorId => {
        const sensorData = dataBySensorId[sensorId];
        lastIndicesBySensor[sensorId] = sensorData.length - 1;

    });
    

  
      setlastIndicesBySensor(lastIndicesBySensor);

    
  
    } catch (error) {
      console.error('An error occurred while loading data:', error);
    }
  };
  


  return (
    <div className="App">
      <div>
        <h1>4Bluetech-Micro-Algae laboratory</h1>
        <h2>- Online data from bioreactors -</h2>
      </div> 

      <div style={{ position: 'absolute', top: 0, right: 0, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <img src={logo} alt="placeholder" style={{ maxWidth: '40%', height: 'auto', marginBottom: '5px' }} />
        <CsvDownloadButton data={fullData} style={{ width: '50%', height: 'auto', marginBottom: '5px' }} />
        <button onClick={() => loadData(selectedValue)} style={{ width: '50%', height: 'auto' }}>Refresh Data</button>
        <label >
          Auto Refresh
          <Switch 
          checked={autoRefresh} 
          onChange={toggleAutoRefresh} 
          id='switch1'/>
        </label>

    </div>

      {/* Select Components */}
      <div>

      <Tabs centered value={selectedTab} onChange={handleChangeTab}>
        <Tab value='co2Tab' label='Experimental measurements' />
        <Tab value='1' label='Sensor set 1' />
        <Tab value='2' label='Sensor set 2' />
        {/* {Object.keys(lastIndicesBySensor).map(sensorId => (         
          <Tab key={sensorId} value={sensorId} label={`Sensor ${sensorId}`} />
        ))} */}
      </Tabs>



      <Select value={selectedTimeRange} style={{ width: 200 }} onChange={(value) => setSelectedTimeRange(value)}>
        <Option value="1">Last Hour</Option>
        <Option value="3">Last 3 Hours</Option>
        <Option value="5">Last 5 Hours</Option>
        <Option value="24">Last Day</Option>
        <Option value="72">Last 3 Days</Option>
        <Option value="168">Last Week</Option>
        <Option value="336">Last 2 Weeks</Option>
        <Option value="504">Last 3 Weeks</Option>
        {/* <Option value="allTime">All Time</Option> */}
      </Select>


      {selectedTab && (
        <Select value={selectedValue} style={{ width: 200 }} onChange={handleChange}>
          {selectedTab === 'co2Tab' ? (
            <>
              <Option value="co2">CO2</Option>
              <Option value="temperature_room">Temperature Room</Option>
              <Option value="Humidity">Humidity</Option>
            </>
          ) : (
            <>
              <Option value="temperature_c">Temperature Liquid</Option>
              <Option value="luminosity">Luminosity</Option>
              <Option value="pH">pH</Option>
            </>
          )}
        </Select>
      )}


      </div>





    {/* Render CO2 Sensor Component */}
    {selectedTab === 'co2Tab' ? (
        <div>
          <CO2SensorComponent
            dataBySensor={dataBySensor}
            lastIndicesBySensor={lastIndicesBySensor}
            selectedValue={selectedValue}
            sensorId={1}
          />
        </div>
      ) : (
        <div>
          <OtherSensorComponent
            dataBySensor={dataBySensor}
            lastIndicesBySensor={lastIndicesBySensor}
            sensorId={selectedTab}
            selectedValue={selectedValue}
          />
        </div>
      )}
    </div>
  );
}

export default App;

  
      